<template>
  <div
    class="container"
    id="program"
    v-title
    :data-title="$t('i18n.matterList')"
  >
    <div id="outer-title">{{ $t("i18n.matterList") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
        @handleSelectionChange="handleSelectionChange"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.audit')"
      v-model="dialogAuditVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form :columns="auditColumns" :option="btnOption"></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.details')"
      v-model="dialogDetailVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-descriptions column="2">
        <el-descriptions-item :label="$t('i18n.name')">
          {{ detailData.name }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.download')">
          <el-button type="primary" id="downloadBtn">
            <a :href="detailData.link">{{ $t("i18n.download") }}</a>
          </el-button>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.fileSize')">
          {{ formatSize(detailData.size) }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.fileType')">
          <el-tag size="small">{{ formatMatterType(detailData.type) }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.createdDate')">
          {{ detailData.createdDate }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.fileInfo')">
          {{ detailData.info }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('i18n.status')">
          <span v-if="detailData.status == 0">
            {{ $t("i18n.toBeReviewed") }}
          </span>
          <span v-else-if="detailData.status == 1" style="color: #67c23a">
            {{ $t("i18n.available") }}
          </span>
          <span v-else-if="detailData.status == 2" style="color: #f56c6c">
            {{ $t("i18n.unavailable") }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.uploadAttachment')"
      v-model="dialogUploadVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item :label-width="0" prop="name">
          <el-upload
            ref="advertFile"
            class="upload-demo"
            :headers="myHeaders"
            :action="$defined.advertUrl + 'material/put-file'"
            :on-success="handleAvatarSuccess"
            :limit="1"
          >
            <el-button size="small" type="primary">
              {{ $t("i18n.uploadAttachment") }}
            </el-button>
            <template #tip>
              <br />上传支持格式(图片:PNG,JPG,JPEG,GIF),(视频:MP4,AVI,FLV,RMVB,MKV),
              (文档：DOCX,XLSX,PPTX),且不超过2GB
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="cancel" @click="dialogUploadVisible = false">
          {{ $t("i18n.close") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "MatterList",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "name",
          search: true,
          width: 320,
          type: "input",
        },
        {
          prop: "info",
          label: "fileInfo",
          align: "center",
        },
        {
          prop: "size",
          label: "fileSize",
          align: "center",
          formatter: (row) => {
            return formatSize(row.size);
          },
        },
        {
          prop: "type",
          label: "fileType",
          align: "center",
          search: true,
          type: "select",
          value: "1",
          props: { label: "locale", value: "value" },
          data: [
            { locale: "all", value: "1" },
            { locale: "picture", value: "2" },
            { locale: "video", value: "3" },
            { locale: "document", value: "4" },
          ],
          formatter: (row) => {
            return formatMatterType(row.type);
          },
          change: (value) => {
            state.matterType = value;
          },
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          formatter: (row) => {
            if (row.status == 0) {
              return `<span>${t("i18n.toBeReviewed")}</span>`;
            } else if (row.status == 1) {
              return `<span style="color:#67c23a">
                ${t("i18n.available")}
              </span>`;
            } else if (row.status == 2) {
              return `<span style="color:#f56c6c">
                ${t("i18n.unavailable")}
              </span>`;
            }
          },
        },
        {
          prop: "createdDate",
          label: "createdDate",
          align: "center",
        },
        {
          label: "operation",
          width: 200,
          formatter: (row) => {
            let str = `<a onclick="handleDetail(${row.id})">
              <i class="el-icon-view"></i>&nbsp;${t("i18n.details")}
            </a>`;
            if (proxy.$defined.btnPermission("删除素材")) {
              str += `<a data-id="${row.id}" onclick="deleteFunc(event)">
                <i class="el-icon-delete"></i>&nbsp;${t("i18n.delete")}
              </a>`;
            }
            if (row.status == 0 && proxy.$defined.btnPermission("审核素材")) {
              str += `<a data-id="${row.id}" onclick="auditFunc(event)">
                <i class="el-icon-finished"></i>&nbsp;${t("i18n.audit")}
              </a>`;
            }
            return str;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加素材"),
            type: "outer",
            method: () => {
              state.dialogUploadVisible = true;
            },
          },
          {
            label: "batchAudit",
            icon: "el-icon-finished",
            class: "orange",
            show: proxy.$defined.btnPermission("审核素材"),
            type: "outer",
            method: () => {
              var ids = state.multipleSelection;
              if (ids.length > 0) {
                matterAudit(ids.join(","));
              } else {
                proxy.$defined.tip(t("i18n.chooseProgram"));
              }
            },
          },
          {
            label: "batchDelete",
            icon: "el-icon-delete",
            class: "import",
            show: proxy.$defined.btnPermission("删除素材"),
            type: "outer",
            method: () => {
              var ids = state.multipleSelection;
              if (ids.length > 0) {
                deleteMatter(ids.join(","));
              } else {
                proxy.$defined.tip(t("i18n.chooseMatter"));
              }
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      dialogAuditVisible: false,
      auditColumns: [
        {
          prop: "id",
          show: false,
          type: "input",
        },
      ],
      matterType: "1",
      btnOption: [
        {
          label: "adopt",
          class: "primary",
          show: true,
          method: (row) => {
            auditMatter(row.id, 0);
          },
        },
        {
          label: "refuse",
          class: "export",
          show: true,
          method: (row) => {
            auditMatter(row.id, 1);
          },
        },
      ],
      multipleSelection: [],
      dialogDetailVisible: false,
      info: "",
      detailData: "",
      dialogUploadVisible: false,
      myHeaders: { Authorization: localStorage.getItem("token") },
    });

    const init = async (params) => {
      state.loading = true;
      let row = getMatterByType(state.matterType, dealData(params));
      const { data } = await proxy.$api.advert.matterList(row);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const dealData = (row) => {
      return {
        current: row.page,
        size: row.pageSize,
        descs: "created_date",
        name: row.name,
      };
    };

    const getMatterByType = (type, row) => {
      if (type == "2") {
        // 图片
        row.type_le = 4;
      } else if (type == "3") {
        // 视频
        row.type_ge = 10;
      } else if (type == "4") {
        // 文档
        row.type_le = 10;
        row.type_ge = 5;
      }
      return row;
    };

    const deleteMatter = async (ids) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.advert.deleteMatter(ids).then(() => {
            init(state.formInline);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    window.deleteFunc = (e) => {
      let id = e.target.dataset.id;
      deleteMatter(id);
    };

    const matterAudit = (id) => {
      state.auditColumns[0].isUpdate = true;
      state.auditColumns[0].value = id;
      state.dialogAuditVisible = true;
    };

    window.auditFunc = (e) => {
      let id = e.target.dataset.id;
      matterAudit(id);
    };

    window.handleDetail = (id) => {
      // 详情
      let row = state.tableData.filter((item) => {
        return item.id == id;
      });
      state.detailData = row[0];
      state.dialogDetailVisible = true;
    };

    const auditMatter = async (ids, status) => {
      await proxy.$api.advert.auditMatter(ids, status);
      init(state.formInline);
      state.dialogAuditVisible = false;
    };

    const handleSelectionChange = (val) => {
      let ids = [];
      val.map((item) => {
        ids.push(item.id);
      });
      state.multipleSelection = ids;
    };

    const formatSize = (val) => {
      if (val < 1 * 1000 * 1000) {
        return (val / 1000).toFixed(2) + "(KB)";
      }
      return (val / (1000 * 1000)).toFixed(2) + "(MB)";
    };

    const formatMatterType = (val) => {
      let type = val < 5 ? "picture" : val < 11 ? "document" : "video";
      return t("i18n." + type);
    };

    const handleAvatarSuccess = (res) => {
      // 上传素材
      if (res.success) {
        init(state.formInline);
        proxy.$defined.tip(
          t("i18n.uploadAttachment") + t("i18n.success"),
          "success"
        );
        state.dialogUploadVisible = false;
      } else {
        proxy.$defined.tip(res.msg);
      }
    };

    return {
      ...toRefs(state),
      init,
      auditMatter,
      handleSelectionChange,
      formatSize,
      formatMatterType,
      handleAvatarSuccess,
    };
  },
};
</script>

<style lang="scss">
#program {
  #downloadBtn {
    border-radius: 20px;
    a {
      color: #fff;
    }
  }
  .el-descriptions {
    td {
      padding-bottom: 20px;
      .el-descriptions__label {
        width: 80px;
        display: inline-block;
      }
    }
  }
  .jlForm {
    .form-button {
      margin: 0;
      text-align: center;
    }
  }
}
</style>
